if (personalLoan === undefined) var personalLoan = {}

personalLoan.globalListeners = (function () {
    function _headerScroll() {
        jQuery(window).scroll(function () {
            if (jQuery(window).scrollTop() >= 100) {
                jQuery('.site-header').removeClass('levitate')
            } else {
                jQuery('.site-header').addClass('levitate')
            }
        })
    }

    // Handle the checkbox, this is the same function used in the business application
    function _checkboxesClick() {
        jQuery('.js-checkbox').on('click', function () {
            jQuery(this).parent().find('input').trigger('click')
            if (jQuery(this).parent().find('input').prop('checked')) {
                jQuery(this).addClass('checked')
            } else {
                jQuery(this).removeClass('checked')
            }
    
            if (jQuery(this).next().prop('checked')) {
                jQuery(this).addClass('checked')
            } else {
                jQuery(this).removeClass('checked')
            }
        })
    }

    // Code from the business form which handles custom dropdown select inputs
    function _dropdownSelects() {
        // First dropdown type
        jQuery('body').on('click', '.bka-dropdown', function (event) {
            event.stopPropagation()
            
            var thisDropdown = jQuery(this).find('.options')
    
            jQuery('.bka-dropdown').find('.options').addClass('hidden')
            jQuery('.bka-dropdown').addClass('options-hidden')
            jQuery('.slide-top').removeClass('push-me-up')
            jQuery('.block').removeClass('push-me-up')
    
            thisDropdown.removeClass('hidden')
            thisDropdown.parent().removeClass('options-hidden')
            jQuery(this).closest('.slide-top').addClass('push-me-up')
            jQuery(this).closest('.block').addClass('push-me-up')
        })

        jQuery('body').on('click', '.bka-dropdown .option', function (event) {
            event.stopPropagation()
    
            jQuery(this).parent().find('.option').each(function () {
                jQuery(this).removeClass('selected')
            })
    
            jQuery('.bka-dropdown').find('.options').addClass('hidden')
            jQuery('.bka-dropdown').addClass('options-hidden')
            jQuery('.slide-top').removeClass('push-me-up')
            jQuery('.block').removeClass('push-me-up')
    
            var selectedText = jQuery(this).html()
            selectedText = selectedText.replace('&amp;', '&')
            if (selectedText.indexOf('img') > -1) {
                // Handle the dropdown with images (only have one special dropdown in the system)
                var imgUrl = jQuery(this).find('img').attr('src')
                selectedText = jQuery(this).find('span').html().replace('&amp;', '&')
                jQuery(this).parent().parent().find('.logo').attr('src', imgUrl)
    
                // Bind desktop UI data (add active class for the item selected)
                jQuery('.business-wrapper .purchase .item').each(function (item) {
                    jQuery(this).removeClass('active slide-top')

                    var itemText = jQuery(this).find('.description').find('p').text()
                    if (selectedText === itemText) {
                        jQuery(this).addClass('active')
                    }
                })
                
                jQuery('.DRS_ui-hack').removeClass('before-selection')
            }
    
            var valueSelected = selectedText;
            if (jQuery(this).data().value !== undefined) {
                valueSelected = jQuery(this).data().value
            }
    
            jQuery(this).addClass('selected')
            jQuery(this).parent().parent().find('input').val(valueSelected)
            jQuery(this).parent().parent().find('input').trigger('change')
        })

        // Second dropdown type
        jQuery('body').on('click', '.bka-dropdown-2', function (event) {
            event.stopPropagation()

            var currentElement = this

            // Handle clicking of the current element, hide all other
            jQuery('.bka-dropdown-2').each(function (index, element) {
                if (element == currentElement) {
                    jQuery(element).find('.options').toggleClass('hidden')
                    jQuery(element).toggleClass('options-hidden')
                    jQuery(element).find('input').toggleClass('white')
                } else {
                    jQuery(element).find('.options').addClass('hidden')
                    jQuery(element).addClass('options-hidden')
                    jQuery(element).find('input').removeClass('white')
                }
            })
        })

        jQuery('body').on('click', '.bka-dropdown-2 .options .option', function () {
            jQuery(this).parent().find('.option').each(function () {
                jQuery(this).removeClass('selected')
            })
            
            var selected = jQuery(this).html()
            jQuery(this).addClass('selected')
            jQuery(this).parent().parent().find('input').val(selected)
            jQuery(this).parent().parent().find('input').trigger('change')
        })

        jQuery('body').on('click', function (event) {
            event.stopPropagation()
            jQuery('.bka-dropdown, .bka-dropdown-2').find('.options').addClass('hidden')
            jQuery('.bka-dropdown, .bka-dropdown-2').addClass('options-hidden')
        })

        // on window resize update height of html document
        // jQuery(window).resize(function () {
        //     console.log ('changed')
        //     jQuery('html').css('height', jQuery('.is-active-route').height())
        // }   ).resize() // trigger resize event

        // on element height change update height of html document
        // jQuery('.is-active-route').bind('DOMSubtreeModified', function () {
        //     jQuery('html').css('height', jQuery('.is-active-route').height())
        // })

        // const someElement = document.querySelector("body");

        
        // var ro = new ResizeObserver(entries => {
        //     for (let entry of entries) {
        //       const cr = entry.contentRect;
        //       console.log('Element:', entry.target);
        //       console.log(`Element size: ${cr.width}px x ${cr.height}px`);
        //       console.log(`Element padding: ${cr.top}px ; ${cr.left}px`);
        //     }
        //   });
          
        //   // Observe one or multiple elements
        //   ro.observe(someElement);
        
    }

    function _radiosChange() {
        $(".js-radios").each(function (index, element) {
            let radiosInput = $(this).find('.js-radios-input')
            let radio = $(this).find('.radio-input')
            radio.each(function (index, element) {
                $(this).on('change', function(e) {
                    radiosInput.val($(this).val())
                    radiosInput.trigger("change")
                })
            })
        });
    }

    function _init () {
        _headerScroll()
        _checkboxesClick()
        _dropdownSelects()
        _radiosChange()
    }

    return {
        init: _init
    }
})()