if (personalLoan === undefined) var personalLoan = {}

personalLoan.applicationOptions = (function () {

  let uploadFormFilesArr = []
  // Check if there are files in the window object
  if (!!window.uploadFormFilesArr && window.uploadFormFilesArr.length > 0) {
    uploadFormFilesArr = window.uploadFormFilesArr
}

  // Populate application options in form when the view is initialized.
  function populateApplicationOptionsInfo(data) {
    // Display checkboxes based on if it is a joint application
    if (data.IsJoint) {
        const jsMainApplicantName = document.querySelector('#js-main-applicant-name')
        const jsCoBorrowerApplicationOptions = document.querySelector('#js-co-borrower-application-options')

        const displayElements = [jsMainApplicantName, jsCoBorrowerApplicationOptions]
        displayElements.forEach(function (element) {
            element.classList.remove('dn-none')
        })

        // Populate with the form data names
        jsMainApplicantName.querySelector('strong').innerHTML = `${data.FirstName} ${data.LastName}`
        jsCoBorrowerApplicationOptions.querySelector('strong').innerHTML = `${data.Personal.CoBorrowerFirstName} ${data.Personal.CoBorrowerLastName}`
    }

    // Listen to option checkboxes
    const jsAplyidOption = document.querySelector('#js-aplyid-option')
    const jsCoBorrowerAplyidOption = document.querySelector('#js-co-borrower-aplyid-option')
    const jsAplyidList = document.querySelector('#js-aplyid-files-list')

    // Main borrower aplyid option changes
    jsAplyidOption.addEventListener('change', function () {
        // User opt in to Aplyid
        $personalLoanForm.my("data").IsCheckedAplyID = jsAplyidOption.checked

        if (jsAplyidOption.checked) {            
            jsAplyidOption.closest('.option-box').classList.add('selected')
            if (data.IsJoint) {
                if ($personalLoanForm.my("data").Personal.CoBorrowerIsCheckedAplyID) {
                    jsAplyidList.classList.add('disabled-list')
                }
            } else {
                jsAplyidList.classList.add('disabled-list')
            }
        } else {
            jsAplyidOption.closest('.option-box').classList.remove('selected')
            jsAplyidList.classList.remove('disabled-list')
        }
    })

    // Co borrower aplyid option changes
    jsCoBorrowerAplyidOption.addEventListener('change', function () {
        // User opt in to Aplyid
        $personalLoanForm.my("data").Personal.CoBorrowerIsCheckedAplyID = jsCoBorrowerAplyidOption.checked

        if (jsCoBorrowerAplyidOption.checked) {           
            jsCoBorrowerAplyidOption.closest('.option-box').classList.add('selected')
            if ($personalLoanForm.my("data").IsCheckedAplyID) {
                jsAplyidList.classList.add('disabled-list')
            }
        } else {
            jsCoBorrowerAplyidOption.closest('.option-box').classList.remove('selected')
            jsAplyidList.classList.remove('disabled-list')
        }
    })

    // Handle file upload on change
    const jsUploadForm = document.querySelector('#js-upload-form')
    jsUploadForm.addEventListener('change', function() {
      handleFiles(this.files)
      handleListFiles()
    })

    // Enable file drop, upload functions
    const jsUploadArea = document.querySelector('#js-upload-area')

    // Prevent default drag behaviors
    const dragDropEvents = ['dragenter', 'dragover', 'dragleave', 'drop']
    dragDropEvents.forEach(eventName => {
        jsUploadArea.addEventListener(eventName, preventDefaults, false)   
        document.body.addEventListener(eventName, preventDefaults, false)
    })

    // Highlight drop area when item is dragged over it
    const dragEnterOver = ['dragenter', 'dragover']

    dragEnterOver.forEach(eventName => {
        jsUploadArea.addEventListener(eventName, highlightArea, false)
    })

    const dragLeaveDrop = ['dragleave', 'drop']
    dragLeaveDrop.forEach(eventName => {
        jsUploadArea.addEventListener(eventName, unHighlightArea, false)
    })

    // Handle dropped files
    jsUploadArea.addEventListener('drop', handleFileDrop, false)

    function preventDefaults(e) {
        e.preventDefault()
        e.stopPropagation()
    }

    function highlightArea() {
        jsUploadArea.classList.add('highlight')
    }

    function unHighlightArea() {
        jsUploadArea.classList.remove('highlight')
    }

    function handleFileDrop(e) {
        const dt = e.dataTransfer
        const files = dt.files

        handleFiles(files)
        handleListFiles()
    }

    function handleFiles(files) {
        files = [...files]

        files.forEach(function(file) {
            uploadFormFilesArr.push(file)
        })

        // remove selected files
        cancelFileUpload(uploadFormFilesArr)
    }

    function handleListFiles() {
        const jsUploadedFileItemList = document.querySelector('#js-uploaded-file-item-list')
        jsUploadedFileItemList.innerHTML = ''

        uploadFormFilesArr.forEach(function(file){
            listUploadedFile(file, validateFileSizeFormat(file))
        })
    }

    function bytesToMegaBytes(bytes) {
        return (bytes / (1024 * 1024)).toFixed(2)
    }

    function validFileFormat(formatArray, type) {
        return formatArray.includes(type)
    }

    function validateFileSizeFormat(file) {
      const validFormats = ['image/jpeg', 'image/png', 'application/pdf']
      const validSize = 20
      const validLength = 100
        let reason = ''

        if (!window.hasUsedUpload) {
            window.hasUsedUpload = true
            window.dataLayer.push({ 'event': 'individual_upload_used' })
		}

        if (
            bytesToMegaBytes(file.size) > validSize ||
            !validFileFormat(validFormats, file.type) ||
            file.name.split('.').pop().toLowerCase() === 'jpeg' ||
            file.name.length > validLength
            ) {
                if (bytesToMegaBytes(file.size) > validSize) {
                    reason = `File size is too large. The maximum file size is ${validSize}MB.`
                    window.dataLayer.push({ 'event': 'individual_upload_error_size' })
                } else if (!validFileFormat(validFormats, file.type)) {
                    reason = `File format is not supported. Please upload a file in PDF, JPG or PNG format.`
                    window.dataLayer.push({ 'event': 'individual_upload_error_type' })
                } else if (file.name.split('.').pop().toLowerCase() === 'jpeg') {
                    reason = `File format is not supported. Please upload a file in PDF, JPG or PNG format.`
                    window.dataLayer.push({ 'event': 'individual_upload_error_jpeg' })
                } else if (file.name.length > validLength) {
                    reason = `File name is too long. The maximum file name length is ${validLength} characters.`
                    window.dataLayer.push({ 'event': 'individual_upload_error_length' })
                }
            return [false, reason]
        } else {
            return [true, reason]
        }
    }

    // Add uploaded file items into the list under the updload area
    function listUploadedFile(file, valid) {
        const jsUploadedFileItemList = document.querySelector('#js-uploaded-file-item-list')

        const itemHtml = `
            <div class="col-12 uploaded-file-item ${valid[0] ? '' : 'invalid-uploaded-file'}">
            
            ${
                valid[0] ? 
                `<svg class="tick-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                </svg>` 
                : 
                `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
                </svg>`
            }

                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M14,2H6A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2M18,20H6V4H13V9H18V20Z" />
                </svg>

                <div class="uploaded-file-info">
                    <span>${file.name}</span>
                    <span>
                        <strong>${bytesToMegaBytes(file.size) + 'mb'}</strong>
                    </span>
                    <span class="uploaded-file-invalid-reason">${valid[1]}</span>
                </div>

                <div class="cancel-upload-file">
                    <span class="js-cancel-file-upload" data-fileName="${file.name}">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <path d="M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19Z" />
                    </svg>
                    Remove file
                    </span>
                </div>
            </div>
            `
        jsUploadedFileItemList.insertAdjacentHTML('beforeend', itemHtml)
    }

    // Cancel file to upload
    function cancelFileUpload(arr) {
      let uploadedFileItemList = document.querySelector('#js-uploaded-file-item-list')

      uploadedFileItemList.addEventListener('click', function(e) {
        if (e.target.classList.contains('js-cancel-file-upload')) {
          e.target.closest('.uploaded-file-item').remove()
          // Remove file from upload files list
          let cancelFilename = e.target.dataset.filename
          for(let i = 0; i < arr.length; i++){ 
            if ( arr[i].name === cancelFilename) {
              arr.splice(i, 1)
              
              let initNewDt = new DataTransfer()
              for (let file of arr)
              if (file !== jsUploadForm.files[0]) 
              initNewDt.items.add(file)
              // Update file input value
              jsUploadForm.files = initNewDt.files
            }
          }
        }
      })
    }
  }

  function onSubmitValidateFileSizeFormat(file) {
    const validFormats = ['image/jpeg', 'image/png', 'application/pdf']
    const validSize = 20
    const validLength = 100

    function bytesToMegaBytes(bytes) {
        return (bytes / (1024 * 1024)).toFixed(2)
    }
  
    function validFileFormat(formatArray, type) {
        return formatArray.includes(type)
    }

    if (
        bytesToMegaBytes(file.size) > validSize ||
        !validFileFormat(validFormats, file.type) ||
        file.name.split('.').pop().toLowerCase() === 'jpeg' ||
        file.name.length > validLength
        ) {
        return false
    } else {
        return true
    }
  }

  return {
    uploadFormFilesArr,
    populateApplicationOptionsInfo,
    onSubmitValidateFileSizeFormat
  }
})();