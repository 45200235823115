$(document).ready(function () {
    // Toggle visibility of application options feature based on theme is loaded
    const searchQueryString = window.location.search.trim().toLowerCase()
    if (!!searchQueryString && searchQueryString.includes('embed=true') && searchQueryString.includes('dealerid')) {
        // replace loading 
        $('.loading-circle').removeClass('hidden')
        $('.loading-box').addClass('hidden')
    }

    if (searchQueryString.includes('logo=false')) {
        $('body').addClass('no-logo-display')
    }

    let themeName = $('#theme-name').val()
    if (themeName) {
        $('#site-logo').attr('src', `/themes/${themeName}/logo/site-logo.png`);
    }

    // We do this to prevent showing original logo before the new one is loaded
    $('#site-logo').removeAttr('style');

    // add required class to input based on require div
    if ($('.suzuki-theme').length > 0 || $('.suzuki-marine-theme').length > 0) {
        $('.error').parent('div').find('label').addClass('required-field-label');
        $('.error').prev('input').prev('label').addClass('required-field-label');
        $('#site-logo').css('width', '100%');
    }
    if ($('.mitsubishi-theme').length > 0) {
        $('.error').parent('div').find('label').addClass('required-field-label');

        $('#driverLicenseType').closest('.row').find('#driverLicenseNumber').prev('label').removeClass('required-field-label');
        $('#driverLicenseType').closest('.row').find('#driverLicenseVersion').prev('label').removeClass('required-field-label');

        $('#driverLicenseType').on('change', function (e) {
            if (e.target.value !== 'No Licence') {
                $(this).closest('.row').find('#driverLicenseNumber').prev('label').addClass('required-field-label');
                $(this).closest('.row').find('#driverLicenseVersion').prev('label').addClass('required-field-label');
            } else {
                $(this).closest('.row').find('#driverLicenseNumber').prev('label').removeClass('required-field-label');
                $(this).closest('.row').find('#driverLicenseVersion').prev('label').removeClass('required-field-label');
            }
        })
    }

    // add label when input has value for VW theme

 
    function checkInputVal(elem) {
        if (elem.val()) {
            elem.prev('label').addClass('show-label')
            elem.parent().siblings('label').addClass('show-label') 
            elem.parents('.row').prev('label').addClass('show-label') 
            elem.next('label').css({
                'display': 'inline'
            })
        } else {
            elem.prev('label').removeClass('show-label')
            elem.parent().siblings('label').removeClass('show-label') 
            elem.parents('.row').prev('label').addClass('show-label') 
            elem.next('label').css({
                'display': 'inline'
            })
        }
    }

    if ($('.vw-theme').length > 0) {
        $("input").on('input change', function(){
            checkInputVal($(this))
            
            $('div.error[style*="display:block"]').css('display', 'inline')
        })

        $('button').on('click', function(){
            $('div.error[style*="display:block"]').css('display', 'inline')
        })

    }

    if ($('.audi-theme').length > 0) {
        $("input").on('input change', function(){
            // commented as it is causing strange behaviour with Audi form lables
            // checkInputVal($(this))
            
            $('div.error[style*="display:block"]').css('display', 'inline')
        })

        $('button').on('click', function(){
            $('div.error[style*="display:block"]').css('display', 'inline')
        })

    }
})
