if (personalLoan === undefined) var personalLoan = {}

personalLoan.otherIncomeTypeCodes = {
    'IN01': 'Rental',
    'IN02': 'Working for Families',
    'IN03': 'Superannuation',
    'IN04': 'WINZ',
    'IN05': 'Boarder',
    'IN06': 'ACC',
    'IN07': 'Investment/shares',
    'IN08': 'Overseas Income',
    'IN09': 'Other'
}

personalLoan.drivingLicenceTypeIDs = {
    0: 'No Licence',
    1: 'Full Licence',
    2: 'Restricted Licence',
    3: 'Learner Licence',
    4: 'International Licence',
    5: 'Disqualified' // Our current app doesn't seem to support this option
}

personalLoan.employmentTypeCodes = {
    'B': 'Beneficiary',
    'A': 'Casual',
    'F': 'Full Time',
    'P': 'Part Time',
    'S': 'Seasonal',
    'E': 'Self Employed',
    'C': 'Contractor',
    'U': 'Unknown'
    // We have an option of 'Unemployed', but it wasn't provided 
}

personalLoan.paymentFrequencyCodes = {
    'W': 'Weekly',
    'F': 'Fortnightly',
    'M': 'Monthly'
}