if (personalLoan === undefined) var personalLoan = {}

personalLoan.helpers = (function () {
    // otherIncomeValue can come both as the other income description or the code itself
    // this function will evaluate what it is and assure the description is always returned
    function _getOtherIncomeDescription (otherIncomeValue) {
        if (!otherIncomeValue) return otherIncomeValue

        var otherIncomeDescription = otherIncomeValue
        for (const key in personalLoan.otherIncomeTypeCodes) {
            if (key === otherIncomeValue) {
                otherIncomeDescription = personalLoan.otherIncomeTypeCodes[key]
                break
            }
        }

        return otherIncomeDescription
    }

    function _getDrivingLicenceTypeDescription (drivingLicenceTypeValue) {
        if (drivingLicenceTypeValue === undefined || drivingLicenceTypeValue === null) return drivingLicenceTypeValue

        var drivingLicenceTypeDescription = drivingLicenceTypeValue

        // We don't have an option for the Disqualified licence type, so just in case this value comes through
        // we default it to the "No licence" driving type
        if (drivingLicenceTypeDescription === 5 || drivingLicenceTypeDescription === '5') {
            drivingLicenceTypeDescription = personalLoan.drivingLicenceTypeIDs[0]
        } else {   
            for (const key in personalLoan.drivingLicenceTypeIDs) {
                // Just do a weak comparison to cover both cases of the value being passed as for eg. 0 or "0", 1 or "1"
                if (key == drivingLicenceTypeValue) {
                    drivingLicenceTypeDescription = personalLoan.drivingLicenceTypeIDs[key]
                    break
                }
            }
        }

        return drivingLicenceTypeDescription
    }

    function _getEmploymentTypeDescription (employmentTypeValue) {
        if (!employmentTypeValue) return employmentTypeValue

        var otherIncomeDescription = employmentTypeValue
        for (const key in personalLoan.employmentTypeCodes) {
            if (key === employmentTypeValue) {
                otherIncomeDescription = personalLoan.employmentTypeCodes[key]
                break
            }
        }

        return otherIncomeDescription
    }

    function _getPaymentFrequencyDescription (paymentFrequencyValue) {
        if (!paymentFrequencyValue) return paymentFrequencyValue

        var otherIncomeDescription = paymentFrequencyValue
        for (const key in personalLoan.paymentFrequencyCodes) {
            if (key === paymentFrequencyValue) {
                otherIncomeDescription = personalLoan.paymentFrequencyCodes[key]
                break
            }
        }

        return otherIncomeDescription
    }
    
    return {
        getOtherIncomeDescription: _getOtherIncomeDescription,
        getDrivingLicenceTypeDescription: _getDrivingLicenceTypeDescription,
        getEmploymentTypeDescription: _getEmploymentTypeDescription,
        getPaymentFrequencyDescription: _getPaymentFrequencyDescription
    }
})();
