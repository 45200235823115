if (personalLoan === undefined) var personalLoan = {}

personalLoan.header = (function () {
    var $header = jQuery('.js-ui-header')
    var $navbarNav = jQuery('#navbarNav')
    var $mobNavBar = jQuery('.form-active')
    function _showApplicationType () {
        $header.find('.js-application-type').show()
    }
    
    function _hideApplicationType () {
        $header.find('.js-application-type').hide()
    }
    
    function _showMenu () {
        $header.find('.js-navbar').addClass('navbar-collapse')
    } 

    function _hideMenu () {
        $header.find('.js-navbar').removeClass('navbar-collapse')
    }

    function _setActiveMenu (routeName) {
        $navbarNav.find('.active').removeClass('active')
        $navbarNav.find('.' + routeName).addClass('active')
        $mobNavBar.find('div').text($navbarNav.find('.' + routeName).text().trim())
    }
    
    return {
        showApplicationType: _showApplicationType,
        hideApplicationType: _hideApplicationType,
        showMenu: _showMenu,
        hideMenu: _hideMenu,
        setActiveMenu: _setActiveMenu
    }
})();
